<template>
  <v-container fluid class="pa-0">
    <v-layout column class="black">
      <v-tabs dark slider-color="yellow" height="35" grow show-arrows v-model="tabIndex">
        <v-tab @click="changeTab('links')">Links</v-tab>
        <v-tab @click="changeTab('travel')">Travel</v-tab>
        <v-tab @click="changeTab('art')">Art</v-tab>
        <v-tab @click="changeTab('meetings')">Meetings</v-tab>
        <v-tab @click="changeTab('articles')">Articles</v-tab>
        <v-tab @click="changeTab('recipies')">Recipies</v-tab>
      </v-tabs>
    </v-layout>
  </v-container>
</template>

<script>
  //import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'HomeView',
    data () {
      return {
        tabIndex: 0,
      }
    },
    methods: {
      changeTab(str) {
        console.log("Switching to " + str);
        if (str == "meetings")
          this.$router.push("/meet");
      }
    },
    created() {
      if (this.$store.state.meetingId) {
        this.$router.push("/meet");
      }
    },
    //components: {
    //  HelloWorld,
    //},
  }
</script>
