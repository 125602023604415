import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import firebase from 'firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyCT9w1KbLRTtiQNQ-q-nA_M7VAi4ZGHEQA",
  authDomain: "spacepolo-d3fe6.firebaseapp.com",
  projectId: "spacepolo-d3fe6",
  storageBucket: "spacepolo-d3fe6.appspot.com",
  messagingSenderId: "896498837048",
  appId: "1:896498837048:web:e9bbcd8b07ba59e38d68cd"
};
firebase.initializeApp(firebaseConfig);
console.log("Initialised link to Firestore");
window.db = firebase.firestore();
window.fsFunc = firebase.firestore;

new Vue({
  created() {
    // Check for url query parms
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('meeting')) {
      var meeting = urlParams.get('meeting');
      store.commit('changeMeetingId', meeting);
      if (urlParams.has("user")) {
        var id = urlParams.get('user');
        store.commit('changeUserId', id);
      }
      else
        store.commit('changeUserId', "");
      console.log("Started with meeting ID = " + meeting + ", userId = " + id);
    }
    else {
      store.commit('changeMeetingId', "");
      store.commit('changeUserId', "");
    }
  },
  router,
  store,
  vuetify,
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  render: h => h(App)
}).$mount('#app')



