<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Spacepolo Logo"
          class="shrink mr-2"
          contain
          src="./assets/polo2.png"
          transition="scale-transition"
          width="60"
        />

        <h1>&nbsp;&nbsp;Spacepolo</h1>
      </div>

      <v-spacer></v-spacer>

      <v-flex v-if="$vuetify.breakpoint.mdAndUp" ><h3>The home of all things Tom Smith</h3></v-flex>

      <v-spacer></v-spacer>

      <v-icon right v-on:click="home()">mdi-home</v-icon>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    home() {
      this.$router.push("/");
    }
  }
};
</script>
